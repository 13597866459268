<template>
	<v-row>
		<v-col cols="12">
			<AddTransactionForm @transactionDetailsChanged="updateDtKey" />
		</v-col>
		<v-col cols="12">
			<datatable :key="dataTableKey"></datatable>
		</v-col>
	</v-row>
</template>

<script>
	import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

	import Datatable from './Datatable'
	import AddTransactionForm from './AddTransactionForm'

	export default {
		components: {
			Datatable,
			AddTransactionForm,
		},
		setup() {
			const dataTableKey = ''

			return {
				dataTableKey,
			}
		},
		methods: {
			updateDtKey: function () {
				let e = this
				e.dataTableKey = e.GenerateRandomKey(4)
			},
		},
	}
</script>
